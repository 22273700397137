/* Carousel CSS Started */

.mainsection1 {
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center;
}

#new_landing_carousel .mainsection1 {
    width: 100%;
    height: 600px; 
    display: grid !important;
    place-items: center !important;
    position: relative;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-align: center !important; 
}


.text_animated,
.image_animated {
    margin: 20px 0px !important;
}

.text_animated h1 {
    font-size: 44px !important;
    line-height: 54px !important;
}


#landing_carousel .text_animated h1 {
    font-size: 44px !important;
    line-height: 54px !important; 
}

#new_landing_carousel .text_animated {
    margin: 0px !important;
    margin-bottom: 40px;
}

#new_landing_carousel .text_animated.mb150 {
    margin-bottom: 150px !important;
}

#new_landing_carousel .text_animated h1 {
    font-size: 44px !important;
    line-height: 54px !important; 
}

#landing_carousel .text_animated h2 {
    font-size: 30px !important;
    line-height: 40px !important;

}

#new_landing_carousel .text_animated h2 {
    font-size: 30px !important;
    line-height: 40px !important;
    margin-bottom: 0px !important;
}

#new_landing_carousel .new-design h2 {
    font-size: 58px !important;
    line-height: 65px !important;
    margin-bottom: 0px !important;
}

#new_landing_carousel .dpabtn_sm1,
#new_landing_carousel .dpabtn_sm2 {
    padding: 6px 20px !important;
    font-size: 14px !important;
}

#new_landing_carousel .text_animated .black3 {
    font-size: 22px !important;
    line-height: 32px !important;
    font-weight: 300 !important;
}


.react-multi-carousel-item--active .text_animated {
    animation-name: zoomIn;
    animation-duration: 2.5s;
    animation-fill-mode: both;
}

.react-multi-carousel-dot--active button {
    background: #1930AB !important;
}

.react-multi-carousel-dot button {
    border-color: #1930AB !important;
}

.react-multi-carousel-dot-list {
    margin-bottom: 20px !important;
}

#new_landing_carousel .mainsection1.web_banner {
    width: 100%;
    height: 600px; 
    display: grid !important;
    place-items: center !important;
    position: relative;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-align: center !important; 
}

.web_banner .MuiList-root {
    padding: 0px !important;
}

.web_banner .MuiListItem-root {
    padding: 0px !important;
}

.web_banner .center_item {
    border-right: 2px solid #00b0f0 !important;
    border-left: 2px solid #00b0f0 !important;
    padding: 0px 8px !important;
}

.web_banner .left-brd {
    border-left: 2px solid #00b0f0 !important;
    padding: 0px 8px !important;
}


.web_banner .teama {
    width: 100px !important;
    height: 100px !important;
    filter: none !important;
}

#new_landing_carousel .web_banner .text_animated {
    margin-bottom: 0px !important;
    margin-top: 35px !important;
}

.web_banner .mobile_only_timer {
    display: none !important;
}


/* oracle section */
#new_landing_carousel .oracle_section {
    width: 100%;
    height: 600px; 
    display: grid !important;
    place-items: center !important;
    position: relative;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-align: center !important; 
}

#new_landing_carousel .oracle_section .text_animated .black3 {
    font-size: 27px !important;
    line-height: 37px !important;
    font-weight: 300 !important;
}

#new_landing_carousel .oracle_section .text_animated h2 {
    font-size: 35px !important;
    line-height: 45px !important;
    margin-bottom: 0px !important;
}

.mg-cover {
    width: 50% !important;
    height: auto;
    padding: 0;
    margin: 0;
    margin-right: 0px !important;
}

.mg-mobCover {
    display: none !important;
}

#new_landing_carousel .topBorder {
    height: 2px !important;
    background-color: white !important;
    width: 40% !important;
    margin-right: auto !important;
}

#new_landing_carousel .bottomBorder {
    height: 2px !important;
    background-color: white !important;
    width: 25% !important;
    margin-left: auto !important;
}

.mt60 {
    margin-top: 60px !important;
}


@keyframes zoomIn {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}


@media (max-width: 959px) { 

    .mt60 {
        margin-top: 0px !important;
    }
}

@media (max-width: 767px) {
    .text_animated h1 {
        font-size: 28px !important;
        line-height: 40px !important;
    }

    .text_animated h2 {
        font-size: 20px !important;
        line-height: 28px !important;
    }

    #landing_carousel .text_animated h1 {
        font-size: 28px !important;
        line-height: 40px !important;
    }

    #landing_carousel .text_animated h2 {
        font-size: 22px !important;
        line-height: 32px !important;

    }

    #new_landing_carousel .text_animated h1 {
        font-size: 28px !important;
        line-height: 40px !important;
    }

    #new_landing_carousel .text_animated h2 {
        font-size: 22px !important;
        line-height: 32px !important;
    }

    #new_landing_carousel .new-design h2{
        font-size: 30px !important;
        line-height: 40px !important;
    }

    #new_landing_carousel .mainsection1 {
        height: 400px !important;
    }

    #new_landing_carousel .web_banner .speaker {
        display: none !important;
    }

    .web_banner .center_item {
        border-right: none !important;
        border-left: none !important;
        padding: 0px !important;
    }

    .web_banner .desktop_only_timer {
        display: none !important;
    }

    .web_banner .mobile_only_timer {
        display: flex !important;
        justify-content: center !important;
        align-items: flex-end !important;
    }

    .web_banner .countdown {
        font-size: 15px !important;
        line-height: 30px !important;
        font-weight: 500 !important;
        padding: 0px 4px !important
    }

    #new_landing_carousel .oracle_section {
        height: 400px !important;
    }

    #new_landing_carousel .oracle_section .text_animated {
        margin: 0px !important;
    }

    #new_landing_carousel .oracle_section .text_animated h2 {
        font-size: 27px !important;
        line-height: 37px !important;
    }

    #new_landing_carousel .oracle_section .text_animated .black3 {
        font-size: 20px !important;
        line-height: 28px !important;
        font-weight: 300 !important;
    }

    #new_landing_carousel .text_animated.mb150 {
        margin-bottom: 70px !important;
    }

    #new_landing_carousel .dpabtn_sm1,
    #new_landing_carousel .dpabtn_sm2 {
        padding: 3px 12px !important;
        font-size: 13px !important;
    }

}

@media (max-width: 613px) {

    .mg-mobCover {
        display: block !important;
    }
}

@media (max-width: 560px) {
    .text_animated h1 {
        font-size: 22px !important;
        line-height: 32px !important;
    }

    .text_animated h2 {
        font-size: 16px !important;
        line-height: 22px !important;
    }

    #landing_carousel .text_animated h1 {
        font-size: 22px !important;
        line-height: 32px !important;
    }

    #landing_carousel .text_animated h2 {
        font-size: 20px !important;
        line-height: 28px !important;
    }

    #new_landing_carousel .text_animated h1 {
        font-size: 22px !important;
        line-height: 32px !important;
    }

    #new_landing_carousel .text_animated h2 {
        font-size: 16px !important;
        line-height: 22px !important;
    }

    #new_landing_carousel .text_animated .black3 {
        font-size: 13px !important;
        line-height: 22px !important;
    }

    #new_landing_carousel .oracle_section .text_animated h2 {
        font-size: 20px !important;
        line-height: 32px !important;
    }

    #new_landing_carousel .oracle_section .text_animated .black3 {
        font-size: 15px !important;
        line-height: 24px !important;
    }

    .web_banner h3 {
        font-size: 13px !important;
        line-height: 26px !important;
    }

    .web_banner .countdown {
        font-size: 13px !important;
        line-height: 26px !important;
        font-weight: 500 !important;
        padding: 0px 4px !important
    }
}

@media (max-width: 460px) {
    #new_landing_carousel .text_animated.mg-title {
        margin: 0px !important;
        margin-bottom: 0px !important;
        text-align: center !important;
    }

    .mainsection1 .magazine-content .flx_fe {
        justify-content: center !important;
    }
}