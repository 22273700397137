#eazydata .pagecontent {
  color: #474858 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  margin-bottom: 14px !important;
}
#eazydata .pagesubhead {
  color: #474858 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  font-weight: 500 !important;
  margin: 5px 0px !important;
}

#eazydata .section {
  padding: 3% 0px;
  padding-bottom: 3% !important;
}
#eazydata .sec_bg {
  background-color: #ffffff;
}

#eazydata .al_right {
  text-align: right !important;
}
#eazydata .al_left {
  text-align: left !important;
}
#eazydata .al_center {
  text-align: center !important;
}
#eazydata .jst {
  text-align: justify !important;
}
#eazydata .al_right_fr {
  text-align: right;
  float: right;
}

#eazydata .link {
  color: inherit !important;
}

#eazydata .bannerhead_a1 {
  color: #1930ab;
  font-size: 40px !important;
  line-height: 50px !important;
  font-weight: 800 !important;
}

#eazydata .wh {
  color: #ffffff !important;
}
#eazydata .bl {
  color: #000 !important;
}
#eazydata .col1 {
  color: #1930ab !important;
}
#eazydata .col2 {
  color: #01b3ef !important;
}
#eazydata .fw5 {
  font-weight: 500 !important;
}

#eazydata .ocean {
  height: 90px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

#eazydata .fs13 {
  font-size: 13px !important;
}
/* New Media Queries */

#eazydata .fhead {
  color: #4d4f5d !important;
}
#eazydata .footer2 {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px !important;
  height: auto !important;
  background-color: #e2e1e1 !important;
}
#eazydata .fItem {
  color: #4d4f5d !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: left !important;
  text-transform: capitalize !important;
}

#eazydata .menubtn {
  font-size: 13px !important;
  text-decoration: none;
  color: #000;
  position: relative;
  font-weight: 500 !important;
  padding: 0px 10px !important;
}
#eazydata .menubtn:hover {
  color: #1930ab !important;
}
#eazydata .flx_fs {
  justify-content: flex-start !important;
  display: flex !important;
}
#eazydata .flx_fs ol {
  margin: 4px !important;
}
#eazydata .collection_banner {
  width: 100% !important;
  min-width: 200px !important;
  max-width: 420px !important;
}
#eazydata .pricingbtn {
  text-transform: none !important;
  font-size: 12px !important;
  background: #1930ab !important;
  padding: 2px 12px 2px 12px !important;
  border-radius: 34px !important;
  margin: 10px 2px !important;
  color: #fff !important;
}
#eazydata .gr_c {
  display: grid !important;
  place-content: center !important;
}
#eazydata .startico {
  height: 18px !important;
  margin-right: -2px !important;
  margin-left: 4px !important;
}
@media (max-width: 350px) {
  #eazydata .menubtn {
    font-size: 12px !important;
  }
}
@media (max-width: 330px) {
  #eazydata .menubtn {
    font-size: 11px !important;
  }
}

#eazydata .subpointhdd {
  color: #1930ab !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  text-align: justify !important;
}
#eazydata .subpoints {
  margin: 0px 20px !important;
  margin-right: 12px !important;
}
#eazydata #annexure .subpoints {
  min-width: 36px !important;
}
@media (max-width: 400px) {
  #eazydata .subpoints {
    margin: 0px 10px !important;
  }
  #eazydata #annexure .subpoints {
    min-width: auto !important;
  }
  #eazydata .flx_fs ol {
    margin-left: -20px !important;
  }
  #eazydata .mobviewallign {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
#eazydata .btmsec {
  padding-bottom: 80px !important;
}
#eazydata .black {
  color: rgb(223, 229, 229) !important;
}
