.salesforcelogo {
  width: 100% !important;
  height: 40px !important;
  margin-right: 20px;
}

#salesforce #new_landing_carousel .text_animated h1 {
  font-size: 36px !important;
  line-height: 45px !important;
}

#salesforce #new_landing_carousel .text_animated h2 {
  font-size: 20px !important;
  line-height: 28px !important;
}

#salesforce .ourstrength .ora_app_iconbx .allic {
  width: 55px !important;
}

#salesforce .ourstrength .str_bg {
height: 100% !important;
}

#salesforce .salesimg img {
  width: 120px !important;
}


#salesforce .boxbg{
  height: 100% !important;
}

#salesforce .ibm_experties{
  height: 100% !important;
}


@media (max-width: 767px) {
  #salesforce .salesimg img {
    width: 80px !important;
  }
  #salesforce #new_landing_carousel .text_animated h1 {
    font-size: 16px !important;
    line-height: 22px !important;
  } 
  #salesforce #new_landing_carousel .text_animated h2 {
    font-size: 16px !important;
    line-height: 22px !important;
  }
}
