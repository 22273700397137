.crm-header .MuiButton-root {
    margin-right: 10px !important;
}

.crm-header .contact_sales_btn {
    margin-top: 3px !important;
}

#crm.mainsection1 {
    height: auto;
}

#crm .skblue {
    color: #77Efff !important;
}

#crm .fitwidth {
    display: block;
    width: fit-content;
}

#crm .flx_left {
    display: flex;
    justify-content: left;
}

#crm h2 {
    font-weight: 700 !important;
}

#crmpage h2 {
    font-size: 40px !important;
    line-height: 54px !important;
}

#crmpage h3 {
    font-size: 26px !important;
    line-height: 34px !important;
    text-transform: capitalize !important;
    letter-spacing: normal !important;
}

#crmpage .blue {
    color: #0d6dff !important;
}

#crmpage .d-blue {
    color: #011e82 !important;
}

#crmpage .black {
    color: black !important;
}

#crmpage .dpabtn_1 {
    background-color: #1930ab !important;
    text-transform: none !important;
    letter-spacing: normal !important;
}

#crmpage .dpabtn_2 {
    background-color: #0fb3f1 !important;
    text-transform: none !important;
    letter-spacing: normal !important;
}

#crmpage .dpabtn_3 {
    background-color: #fff !important; 
    border-radius: 8px !important;
    padding: 11px 30px;
    text-transform: none !important;
    font-size: 14px !important;
    letter-spacing: normal !important;
}


.borderbx {
    border: solid 2px #0000ff !important;
    padding: 15px !important;
    border-radius: 8px !important;
    background-color: #f2f3f9 !important;
}

#comprehensive .flx_fs {
    align-items: start !important;
}

#crm .whitebx {
    padding: 10px !important;
}

#crm .txt_btn {
    color: #ffffff !important; 
    min-width: fit-content !important;
    text-decoration: underline !important;


}

#crm .label2 {
    font-size: 13px !important;
    line-height: 16px !important;
    font-weight: 400 !important;
    color: #000000 !important;
    -webkit-user-select: none !important;
    user-select: none !important;
}

#crm .txt_btn:hover {
    color: #ffffff !important; 
}

#aboutus .topbanner2 {
    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
}

#aboutus .dpabtn_1 {
    background-color: transparent !important;
    color: #0d6dff !important;
    border-radius: 26px !important;
    font-size: 16px !important;
    padding: 2px 7px 2px 18px !important;
    text-transform: none !important;
    border: solid 2px #0d6dff !important;
    box-shadow: none !important;
    letter-spacing: normal !important;
}

.dpabtn_1 .arrow2 {
    background: #0d6dff !important;
    border: 3px solid #fff !important;
    border-radius: 50% !important;
    color: #fff !important;
    margin-left: 15px !important;
    padding: 5px !important;
    font-size: 40px !important;
}

#comprehensive .MuiIconButton-root {
    padding: 0px 12px !important;
    position: relative !important;
    top: 5px !important;
}

#comprehensive .MuiIconButton-root:hover {
    background-color: #fff !important;
}

#comprehensive .sba_icon {
    width: 70px !important;
}

#comprehensive .sba_iconbx {
    height: 80px !important;
    width: 80px !important;
}

#comprehensive .sbalenders_benefitbx {
    height: 440px !important;
}

#get_proposal_formbg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#crmcontact_form .alltxfieldlg.textfield1 .MuiOutlinedInput-input {
    font-size: 12px !important;
    padding: 12PX !important;
}

#crmcontact_form .alltxfieldlg.textfield1 .MuiFormLabel-root.MuiInputLabel-shrink,
#crmfooterform .MuiInputLabel-outlined.MuiInputLabel-marginDense.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.85) !important;
    font-weight: 400 !important;
}

#crmcontact_form .PhoneInputCountry {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    background: white !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

#crmfooterform .PhoneInputCountry {
    border: solid 2px #ffff !important;
    border-radius: 13px !important;
    background: transparent !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

#crmfooterform .MuiOutlinedInput-input {
    padding: 16px 12px !important;
}

#crmfooterform .requirement .MuiOutlinedInput-input,
#crmcontact_form .requirement .MuiOutlinedInput-input {
    padding: 0px !important;
}

#crmfooterform .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 19px) scale(1) !important;
}

#crmfooterform .PhoneInputCountryIcon,
#crmfooterform .PhoneInputCountrySelectArrow {
    color: #fff !important;
}

#crmcontact_form .alltxfieldlg.textfield1 .MuiFormLabel-root {
    transform: translate(14px, 14px) scale(1) !important;
}

#crmcontact_form .alltxfieldlg.textfield1 .MuiInputBase-root {
    background-color: #ffff !important;
}

#crmcontact_form .link {
    color: #0d6dff !important;
    text-decoration: underline #0d6dff !important;
}

#get_proposal_formbg .alltxfieldlg.textfield1 .MuiOutlinedInput-input {
    font-size: 12px !important;
    padding: 18PX !important;
}

#get_proposal_formbg .alltxfieldlg.textfield1 .MuiFormLabel-root.MuiInputLabel-shrink {
    transform: translate(14px, 7px) scale(0.85) !important; 
}

#get_proposal_formbg .alltxfieldlg.textfield1 .MuiFormLabel-root {
    transform: translate(14px, 21px) scale(1) !important;
    font-weight: 600 !important;
}

#get_proposal_formbg .alltxfieldlg.textfield1 .MuiInputBase-root {
    background-color: #ffff !important;
}

#get_proposal_formbg .alltxfieldlg .MuiFilledInput-root {
    border-bottom: none;
}

#crmfooterform .alltxfieldlg2.textfield1 .MuiInputBase-root {
    background-color: transparent !important;
    outline: none !important;
    color: #ffff !important;
}

#crmfooterform .alltxfieldlg2.textfield1 .MuiFormLabel-root {
    font-size: 12px !important;
    color: #ffffff !important;
    font-weight: 400 !important;
}

#crmfooterform .alltxfieldlg2.textfield1 .MuiOutlinedInput-notchedOutline {
    border: solid 2px #ffff !important;
    border-radius: 13px !important;
}

#get_proposal_formbg .MuiFilledInput-inputMarginDense {
    padding: 27px 12px 10px !important;
}

#get_proposal_formbg .phone .MuiFilledInput-root {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

#get_proposal_formbg .PhoneInputCountry {
    background-color: #fff !important;
    border-radius: 4px;
    position: relative;
    transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: none !important;
    padding: 10px 12px !important;
    margin-right: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

#get_proposal_formbg .alltxfieldlg .MuiFilledInput-root.MuiFilledInput-underline::before,
#get_proposal_formbg .alltxfieldlg .MuiFilledInput-underline::after {
    border: none !important;
}

#crm .crmbtn_1 {
    background-color: #ffff !important;
    color: #0d6dff !important;
    border: solid 2px #0d6dff !important;
    border-radius: 26px !important; 
    padding: 5px 12px 5px 18px !important;
    text-transform: none !important;
    font-size: 14px !important;
}

.crmbtn_1 .MuiButton-iconSizeMedium> :first-child {
    background: #0d6dff !important;
    border-radius: 50% !important;
    color: #fff !important;
    margin-left: 15px !important;
    padding: 5px !important;
    font-size: 24px !important;
}

#get_proposal_formbg .crmbtn_2 {
    background-color: #273544 !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    padding: 11px 30px;
    text-transform: none !important;
    font-size: 14px !important;
}

.crm_toolsbx {
    background-color: #eeeaea !important;
    padding: 12px !important;
    border-radius: 12px !important;
    border: solid 2px transparent !important;

}

.crm_toolsbx:hover {
    border: solid 2px #0d6dff !important;
}


#tools_technologies .sba_icon {
    height: 50px !important;
    width: auto !important;
    max-width: 100% !important;
}

.why_choose .sba_icon {
    width: 60px !important;
}

.why_choose .sbalenders_benefitbx {
    min-height: 293px !important;
}

.why_choose .sbalenders_benefitbx:hover {
    background-color: #fff !important;
}

#tools_technologies .w40 {
    width: 20% !important;
}

#tools_technologies h6 {
    font-size: 14px !important;
    line-height: 20px !important;
}

#tools_technologies .toolsbx {
    height: 80px !important;
    width: 100px !important;
}

#success-stories .mainbox { 
    position: relative !important;
}

#success-stories .parentimg {
    width: 100% !important;
    height: auto !important; 
    position: relative !important;
    margin-left: 56px;
}

#success-stories .childimg {
    position: absolute;
    left: 25px !important;
    top: 239px !important;
    width: 20% !important;
    z-index: -999 !important;
}

#success-stories .line {
    width: 75px;
    height: 3px;
    border-radius: 20px;
    background-color: #e68913 !important;
    transition: width 2s;
}

#success-stories .bluebx {
    background-color: #d4f4ff !important;
    padding: 16px !important;
}

#success-stories .blinebx {
    display: flex !important;
    position: relative !important;
}

#success-stories .bline1 {
    height: 144px !important;
    width: 8px !important;
    background: #00b0f0 !important;
}

#success-stories .bline2 {
    height: 50px !important;
    width: 4px !important;
    background: linear-gradient(180deg, rgba(0, 176, 240, 1) 0%, rgba(25, 48, 171, 1) 78%);
    position: absolute;
    left: -1px !important;
    border-radius: 20pc !important
}

#success-stories .result_img {
    width: 80px !important;
}

#success-stories .storiesbx {
    padding-left: 40px !important;
}

.dpabtn_3 {
    background-color: #fff !important;
    color: #4d4f5d !important;
    border-radius: 50px !important;
    padding: 8px 28px !important;
    letter-spacing: 2px !important;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
}

.footersection {
    width: 100% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: auto !important;
}

#success-stories .bl-bdr {
    border: 1px solid #10b4f3 !important;
    padding: 16px !important;
}

#crmfooterform .phone-error,
#crmfooterform .MuiFormHelperText-root {
    color: #000 !important;
    background-color: #fff !important;
    padding: 3px 12px !important;
    border-radius: 26px !important;
    font-size: 13px !important;
    width: fit-content !important;
    margin-left: 0px;
    margin-right: 0px;
}

#crmcontact_form .MuiOutlinedInput-multiline {
    padding: 0px !important;
} 

#crmfooterform input:-webkit-autofill,
#crmfooterform input:-webkit-autofill:hover,
#crmfooterform input:-webkit-autofill:focus,
#crmfooterform textarea:-webkit-autofill,
#crmfooterform textarea:-webkit-autofill:hover,
#crmfooterform textarea:-webkit-autofill:focus,
#crmfooterform select:-webkit-autofill,
#crmfooterform select:-webkit-autofill:hover,
#crmfooterform select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

@media (max-width: 1255px) {
    #success-stories .bline1 {
        height: 156px !important;
        width: 8px !important;
        background: #00b0f0 !important;
    }
}

@media (max-width: 1247px) {
    #success-stories .parentimg {
        margin-left: 32px;
    }

    #success-stories .childimg {
        position: absolute;
        left: 2px !important;
        top: 235px !important;
        width: 20% !important;
    }
}

@media (max-width: 1147px) {
    #success-stories .bline1 {
        height: 180px !important;
        width: 8px !important;
        background: #00b0f0 !important;
    }
}

@media (max-width: 1040px) {
    #success-stories .parentimg {
        margin-left: 32px;
    }

    #success-stories .childimg {
        left: 5px !important;
        top: 190px !important;
        width: 20% !important;
    }

    #success-stories .storiesbx {
        padding-left: 24px !important;
    }
}

@media (max-width: 1147px) {
    #success-stories .bline1 {
        height: 208px !important;
        width: 8px !important;
        background: #00b0f0 !important;
    }
}


@media (max-width: 960px) {

    #comprehensive .sbalenders_benefitbx {
        height: auto !important;
        min-height: auto !important;
    }

    #comprehensive .mob_flex {
        display: flex !important;
        align-items: center !important;
    }

    #comprehensive .two {
        min-height: auto !important;
    }

    #comprehensive .sba_icon {
        width: 40px !important;
        margin-right: 10px !important;
    }

    #comprehensive .sba_iconbx {
        height: 60px !important;
        width: 60px !important;
    }

}

@media (max-width: 959px) {
    #success-stories .parentimg {
        width: 80% !important;
        margin-left: 88px !important;
    }

    #success-stories .childimg {
        left: 46px !important;
        top: 280px !important;
        width: 18% !important;
    }

    #success-stories .storiesbx {
        padding-left: 0px !important;
    }

    #success-stories .bline1 {
        height: 110px !important;
        width: 4px !important;
        background: #00b0f0 !important;
    }

    #success-stories .bline2 {
        height: 40px !important;
        width: 4px !important;
        background: linear-gradient(180deg, rgba(0, 176, 240, 1) 0%, rgba(25, 48, 171, 1) 78%);
        position: absolute;
        left: -1px !important;
        border-radius: 20pc !important
    }
}

@media (max-width: 876px) {
    #success-stories .parentimg {
        width: 80% !important;
        margin-left: 72px !important;
    }

    #success-stories .childimg {
        left: 42px !important;
        top: 240px !important;
        width: 16% !important;
    }

    .crm-header .contact_sales_btn {
        margin-top: 0px !important;
    }
}


@media (max-width: 767px) {
    #success-stories .result_img {
        width: 60px !important;
    }

    #success-stories .bline1 {
        height: 100px !important;
        width: 4px !important;
        background: #00b0f0 !important;
    }

    #success-stories .bline2 {
        height: 40px !important;
        width: 4px !important;
        background: linear-gradient(180deg, rgba(0, 176, 240, 1) 0%, rgba(25, 48, 171, 1) 78%);
        position: absolute;
        left: -1px !important;
        border-radius: 20pc !important
    }

    #crmpage h2 {
        font-size: 25px !important;
        line-height: 32px !important;
    }

    #crmpage h3 {
        font-size: 18px !important;
        line-height: 24px !important;
        text-transform: capitalize !important;
        letter-spacing: normal !important;
    }

    #crm .text_animated {
        padding-top: 24px !important;
    }

    #aboutus .mob_c {
        text-align: center !important;
        margin-bottom: 16px !important;
    }

    #aboutus .dpabtn_1 {
        padding: 2px 6px 2px 18px !important;
        font-size: 13px !important;
    }

    #crmcontact_form .dpabtn_1 {
        padding: 5px 10px 5px 18px !important;
        font-size: 13px !important;
    }

    #crmpage .dpabtn_2,
    #get_proposal_formbg .crmbtn_2,
    #crmpage .dpabtn_3 {
        padding: 5px 12px !important;
        font-size: 13px !important;
    }

    .dpabtn_1 .arrow2 {
        margin-left: 8px !important;
        font-size: 30px !important;
    }

    #get_proposal_formbg h4,
    #comprehensive h4 {
        font-size: 15px !important;
        line-height: 20px !important;
    }
}

@media (max-width: 678px) {
    #success-stories .parentimg {
        width: 80% !important;
        margin-left: 72px !important;
    }

    #success-stories .childimg {
        left: 42px !important;
        top: 210px !important;
        width: 16% !important;
    }

    #success-stories .result_img {
        width: 60px !important;
    }

    #success-stories .bline1 {
        height: 110px !important;
        width: 4px !important;
        background: #00b0f0 !important;
    }
}

@media (max-width: 600px) {
    #success-stories .section {
        padding-top: 50px !important;
    }

    #tools_technologies .w40 {
        width: 40% !important;
    }

    #comprehensive .sba_icon {
        width: 30px !important;
    }

    #comprehensive .sba_iconbx {
        height: 50px !important;
        width: 50px !important;
    }

    .why_choose .sbalenders_benefitbx {
        min-height: auto !important;
    }

    #success-stories .parentimg {
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 12px !important;
    }

    #success-stories .childimg { 
        display: none !important;
    }

    #success-stories .bline1 {
        height: auto !important;
        width: 2px !important;
        background: #00b0f0 !important;
    }

    #success-stories .bline2 {
        height: 25px !important;
        width: 4px !important;
        background: linear-gradient(180deg, rgba(0, 176, 240, 1) 0%, rgba(25, 48, 171, 1) 78%);
        position: absolute;
        left: -2px !important;
        border-radius: 20pc !important
    }

    .why_choose.section2 {
        padding-bottom: 25px !important;
    }

    #get_proposal_formbg .crmbtn_2 {
        margin-bottom: 16px !important;
    }

}