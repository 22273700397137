.appnavbar .MuiToolbar-regular {
	min-height: 60px;
}

.appnavbar {
	background: #fff !important;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
	min-height: 40px !important;
	z-index: +9 !important;
}

.dpalogo {
	width: 175px;
	top: 2px;
	position: relative;
	margin-right: 8px !important;
}

.smdpalogo {
	width: 30px;
	top: 2px;
	position: relative;
	margin-right: 8px !important;
	display: none !important;
}

.oraclelogo {
	width: 175px;
	top: 2px;
	position: relative;
	margin-right: 8px !important;
}

.ibmlogo {
	height: 35px !important;
	margin: 5px 10px 0 8px;
	/* border-right: 2px solid black !important; */
}

.clickuplogo {
	width: 80px !important;
	margin-right: 8px !important;
}

.loggo {
	padding: 8px 0px !important;
}

.logodesk {
	display: block;
}

.logomob {
	display: none;
}

.mainnavbar {
	background-color: #ffffff !important;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05) !important;
}


.sectionDesktop .MuiAvatar-root {
	width: 30px;
	height: 30px;
	display: flex;
	overflow: hidden;
	position: relative;
	font-size: 16px;
	align-items: center;
	flex-shrink: 0;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	line-height: 1;
	border-radius: 50%;
	justify-content: center;
}

.sectionDesktop .MuiIconButton-root {
	flex: 0 0 auto;
	color: rgba(0, 0, 0, 0.54);
	padding: 6px;
	overflow: visible;
	font-size: 1.5rem;
	text-align: center;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 50%;
}


.getpremium {
	position: relative;
	top: 6px;
}

.getprem {
	display: none !important;
}

.getprem1 {
	display: block;
}


.menuitem li {
	padding-top: 0px !important;
	padding-left: 0px;
	padding-bottom: 0px;
}

.bdd {
	padding-bottom: 8px !important;
	border-bottom: 1px solid #00000022 !important;
	margin-bottom: 8px !important;
}

.bdd .fs11 {
	font-size: 11px !important;
}

.bdd_b {
	padding-bottom: 8px !important;
}

.linkitem span {
	padding-bottom: 5px !important;
	font-size: 13px !important;
	text-decoration: none !important;
	color: #1930AB;
	position: relative;
	font-weight: 500 !important;
	line-height: 20px;
	letter-spacing: 0px;
	text-overflow: ellipsis !important;
	overflow: hidden !important;
}

.menudscrp {
	color: #b1b1b1 !important;
	font-size: 11px !important;
	line-height: 15px !important;
	text-align: left;

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	min-height: 30px !important;
}

.menuitem li a {
	font-size: 13px !important;
	text-decoration: none !important;
	color: #4D4F5D;
	position: relative;
	font-weight: 500 !important;
	margin: 0px;
	padding: 0px;
	line-height: 8px;
}

.menuitem li a span {
	position: relative;
	top: -7px;
}

.menuitem .MuiListItem-button:hover {
	background-color: transparent !important;
}


.sti {
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: 99;
	background-color: #EAEAEA !important;
	padding-bottom: 9px;
	border-bottom: 4px solid #1930ab;
}

.st_middle {
	border-radius: 4px;
	padding: 14px 0px 0px 0px;
}


.respon {
	width: 100%;
	max-width: 800px;
	height: auto;
}

.menuheading {
	color: #01B4F0 !important;
	font-size: 13px !important;
	font-weight: 600 !important;
	letter-spacing: 2px !important;
	margin-bottom: 10px !important;
	text-transform: uppercase !important;
}

.iconimg {
	margin-right: 10px;
	width: 25px;
}


.menuitem .MuiPopover-paper {
	top: 46px !important;
	padding: 5px 13px !important;
	width: 490px !important;
	right: 7px !important;
	/* left: auto !important; */
	border-radius: 6px !important;
	max-height: auto !important;
	box-shadow: 0 0px 5px rgb(0 0 0 / 20%) !important;
}

.menuitem.solutions .MuiPopover-paper {
	width: 850px !important;
}

.useraddicon {
	display: none !important;
}

.signicons {
	display: block !important;
}

.grow {
	flex-grow: 1;
}

.sectionDesktop {
	display: flex;
}

.logintitle {
	align-items: center !important;
	display: flex !important;
	margin: 2px !important;
	font-size: 13px !important;
	color: #000 !important;
	text-decoration: none !important;
	font-weight: 600 !important;
}

.menutt {
	align-items: center !important;
	display: flex !important;
	margin: 0px 0px !important;
	font-size: 13px !important;
	color: #000 !important;
	text-decoration: none !important;
	font-weight: 500 !important;
	background-color: transparent !important;
	text-transform: capitalize !important;
}


.logintitle:hover {
	text-decoration: none !important;
	color: #2357f3 !important;
}

.logintitle .lgsgicon {
	color: #f00 !important;
	display: none !important;
}

.profileimg {
	align-items: center !important;
	display: flex !important;
	margin: 10px 10px !important;
	font-size: 10px !important;
	width: 30px !important;
	height: 30px !important;
	cursor: pointer;
}

.iconapp:hover {
	background-color: transparent !important;
}



.insight_menu .MuiPopover-paper {
	top: 46px !important;
	padding: 5px 13px !important;
	width: 180px !important;
	border-radius: 6px !important;
	max-height: auto !important;
	box-shadow: 0 0px 5px rgb(0 0 0 / 20%) !important;
}



.insight_menu li {
	padding-top: 0px !important;
	padding-left: 0px;
	padding-bottom: 0px;
}



.insight_menu li a {
	font-size: 13px !important;
	text-decoration: none !important;
	color: #4D4F5D;
	position: relative;
	font-weight: 500 !important;
	margin: 0px;
	padding: 0px;
	line-height: 8px;
}

.insight_menu li a span {
	position: relative;
	top: -7px;
}

.insight_menu .MuiListItem-button:hover {
	background-color: transparent !important;
}


.esg_menu .MuiPopover-paper {
	width: auto !important;
}

.active,
.selected {
	color: #00b0f0 !important;
}

@media (max-width: 1146px) {
	.withora {
		display: none !important;
	}

	.smdpalogo {
		display: block !important;
	}
}

@media (max-width: 1001px) {
	.oraclelogo {
		width: 130px !important;
	}

	.ibmlogo {
		position: relative !important;
		top: 4px !important;
	}
}


@media (max-width: 930px) {
	.menutt {
		padding: 0px !important;
		padding-left: 8px !important;
		font-size: 12px !important;
	}

	.contact_sales_btn {
		font-size: 13px !important;
		color: #fff !important;
		font-weight: 600 !important;
		text-transform: capitalize !important;
		padding: 0px 12px !important;
		height: 25px !important;
	}
}


@media (max-width: 840px) {
	.menutt {
		padding: 0px !important;
		padding-left: 8px !important;
		font-size: 12px !important;
	}

	.appnavbar .MuiButton-endIcon {
		margin-left: 2px !important;
	}

	.contact_sales_btn {
		font-size: 13px !important;
		color: #fff !important;
		font-weight: 600 !important;
		text-transform: capitalize !important;
		padding: 0px 6px !important;
		height: 25px !important;
	}
}

@media (max-width: 876px) {
	.contact_sales_btn {
		background-color: #00b0f0 !important;
		border-radius: 50px !important;
		padding: 0px 15px !important;
		font-size: 15px !important;
		color: #fff !important;
		text-decoration: none !important;
		font-weight: 700 !important;
		text-transform: capitalize !important;
		height: 28px !important;
		margin-top: 5px !important;
		margin-left: 5px !important;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
	}

	.mobilemenuview .MuiAccordionDetails-root {
		padding-left: 25px !important;
	}

	.mobilemenuview .linkitem span {
		font-size: 12px !important;
		line-height: 16px !important;
	}

	.mobilemenuview .MuiMenuItem-root {
		min-height: 20px !important;
		padding: 4px 0px !important;
	}

	.mobilemenuview .MuiMenuItem-root:hover {
		background-color: transparent !important;
	}

	.mobilemenuview .menutt {
		padding-left: 8px !important;
	}

	.mobilemenuview .MuiButton-label {
		justify-content: flex-start !important;
	}

	.menudscrp {
		min-height: auto !important;
	}

	.oraclelogo {
		width: 175px !important;
	}
}

.menutt:hover {
	color: #00b0f0 !important;
}

.bdd .nested {
	font-size: 12px !important;
}

.bdd.no_br {
	border-bottom: none !important;
	margin-bottom: 0px !important;
}

.solutions .bdd {
	margin-bottom: 0px !important;
	padding:12px !important;
}

.bddln {
	border: 1px solid #0002 !important;
	border-left: none !important;
	padding: 12px !important;
}

.bddoall {
	border: 1px solid #0002;
	padding: 12px !important;
}

.bddrn {
	border: 1px solid #0002 !important;
	border-right: none !important;
	padding: 12px !important;
}

.solutions .bdd.esgbx {
	border-left: 1px solid #0002 !important;
	border-right: 1px solid #0002 !important;
	padding:12px !important;
	border-bottom: none !important;
}

  /* .solutions .esgopbx .linkitem.active_drop_menu span {
	font-weight: 500 !important;
	color: #1930ab !important;
  } */

   .esgopbx .linkitem span{
	color: #00b0f0 !important;
  }

    .invebx .linkitem span{
	color: #00b0f0 !important;
  }

  .solutions .esgopbx .linkitem span {
	font-size: 12px !important;
	font-weight: 500 !important;
	color: #00b0f0 ;
  }

  /* .solutions .invebx .linkitem.active_drop_menu span {
	font-weight: 500 !important;
	color: #1930ab !important;
  } */

  .solutions .bdd .invebx .linkitem span {
	font-size: 12px !important;
	font-weight: 500 !important;
	color: #00b0f0 ;
  } 

  .solutions .bdd.invebx1{
	margin-top: -1px !important;
  }

.solutions .linkitem span{
	overflow: visible !important;
}

/* .bddln.pb3 {
	padding-bottom: 35px !important;
} */

.solutions .ptn {
	border-top: none !important;
}

.solutions .prn {
	border-right: none !important;
}

.solutions .pbn {
	border-bottom: none !important;
}

.solutions .pln {
	border-left: none !important;
} 

.corser_none{
	cursor: context-menu !important;
}