h1.h1h1 {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    margin: 5px 0px !important;
    font-family: "Poppins", sans-serif !important;
    text-transform: capitalize;
}

h2.h2h4 {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    margin: 5px 0px !important;
    font-family: "Poppins", sans-serif !important;
    text-transform: capitalize;
}

h3.h3h4 {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    margin: 5px 0px !important;
    font-family: "Poppins", sans-serif !important;
    text-transform: capitalize;
    letter-spacing: 0px !important;
}

h3.stay1 {
    font-size: 20px !important;
    color: #ffffff !important;
    line-height: 30px !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif !important;
    text-transform: none !important;
    letter-spacing: 0px !important;
}

h3.h3h6 {
    font-size: 16px !important;
    line-height: 26px !important;
    font-weight: 600 !important;
    margin-bottom: 14px !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    font-family: "Roboto", sans-serif !important;
}

@media (max-width: 767px) {

    h2.h2h4,
    h3.h3h4 {
        font-size: 14px !important;
        line-height: 22px !important;
    }

    h3.h3h6 {
        font-size: 13px !important;
        line-height: 20px !important;
    }

    h3.stay1 {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}