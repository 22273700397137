#investor_relations h1 {
    font-size: 30px !important;
    line-height: 42px !important;
    font-weight: 500 !important;
    font-family: "Poppins", sans-serif !important;
    text-transform: capitalize;
    margin-bottom: 25px;
}

#investor_relations h2 {
    font-size: 26px !important;
    line-height: 36px !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif !important;
}

#investor_relations h3 {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    margin: 5px 0px !important;
    letter-spacing: normal !important;
    font-family: "Poppins", sans-serif !important;
    text-transform: capitalize;
}







@media (max-width: 767px) {
    #investor_relations h1 {
        font-size: 26px !important;
        line-height: 32px !important;
    }

    #investor_relations h3 {
        font-size: 13px !important;
        line-height: 20px !important;
    }

    #investor_relations #product_cta.flx_fs {
        display: block !important;
        justify-content: center !important;
        text-align: center !important;
    }
}

@media (max-width: 600px) {
    #investor_relations h1 {
        font-size: 18px !important;
        line-height: 28px !important;
        margin-bottom: 0px !important;
        font-weight: 400 !important;
    }

    #investor_relations h2 {
        font-size: 16px !important;
        line-height: 24px !important;
    }

    #investor_relations h3 {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    #investor_relations .mob_center {
        justify-content: center !important;
    }
}