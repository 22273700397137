#cookiescript_injected {
  font-family: "Roboto", sans-serif !important;
  background-color: #2a2a2e !important;
  font-size: 12px !important;
}

#cookiescript_reject {
  background-color: #2a2a2e !important;
}

#cookiescript_badgesvg {
  width: 25px !important;
  height: 25px !important;
}

#cookiescript_badgeimage {
  width: 25px !important;
  height: 25px !important;
}

#cookiescript_checkboxs {
  margin: 4px auto 4px auto !important;
}

#cookiescript_badgetext {
  font-size: 12px !important;
  max-width: 0;
  line-height: 12px !important;
  display: flex !important;
  align-items: center !important;
}

span.cookiescript_checkbox_text {
  font-size: 11px !important;
  font-weight: 400 !important;
  text-transform: none !important;
}

#cookiescript_injected .mdc-checkbox .mdc-checkbox__native-control {
  width: 14px !important;
  height: 14px !important;
}

#cookiescript_injected .mdc-checkbox__background,
#cookiescript_injected_fsd .mdc-checkbox__background {
  width: 14px !important;
  height: 14px !important;
}

#cookiescript_injected .mdc-checkbox {
  padding: 10px !important;
}

#cookiescript_injected .mdc-checkbox .mdc-checkbox__native-control,
#cookiescript_injected_fsd .mdc-checkbox .mdc-checkbox__native-control {
  top: 10px !important;
  left: 10px !important;
}

#cookiescript_injected .mdc-checkbox .mdc-checkbox__background,
#cookiescript_injected_fsd .mdc-checkbox .mdc-checkbox__background {
  top: 12px !important;
  left: 12px !important;
}

#cookiescript_accept,
#cookiescript_reject,
#cookiescript_save {
  line-height: 28px !important;
  height: 28px !important;
  font-size: 11px !important;
}

#cookiescript_injected .mdc-checkbox .mdc-checkbox__background::before,
#cookiescript_injected_fsd .mdc-checkbox .mdc-checkbox__background::before {
  top: -11px !important;
  left: -11px !important;
  width: 32px !important;
  height: 32px !important;
}

#cookiescript_description {
  margin: 0px auto 0px !important;
  text-align: center;
  max-width: 600px;
}

#cookiescript_copyright {
  display: none !important;
}

#cookiescript_manage_wrap {
  margin: 0px !important;
}

#cookiescript_injected,
#cookiescript_reject {
  background-color: #fff !important;
}