.career .MuiTab-root {
  width: auto !important;
  background: #ffffff !important;
  margin-right: 14px !important;
  color: #85898d;
  font-size: 12px !important;
  min-height: auto !important;
  min-width: auto !important;
  text-transform: capitalize !important;
  position: relative;
  border-radius: 20px;
  padding: 8px 30px;
  opacity: 1 !important;
}

.maintabbedsection .MuiTabs-scrollButtonsDesktop {
  display: block !important;
  display: inline-flex !important;
  color: #303f9f !important;
}

.maintabbedsection .tabbedbar {
  background-color: transparent !important;
  box-shadow: none !important;
}

.maintabbedsection .MuiTab-root {
  width: auto !important;
  background: #ffffff !important;
  margin-right: 14px !important;
  color: #85898d !important;
  font-size: 12px !important;
  min-height: auto !important;
  min-width: auto !important;
  text-transform: capitalize !important;
  position: relative;
  border-radius: 20px;
  padding: 8px 30px;
}

.maintabbedsection .ltchild {
  padding-right: 15px;
}

.maintabbedsection .MuiTab-root .MuiSvgIcon-root {
  position: absolute;
  right: 4px;
  width: 18px;
  top: 6px;
}

.maintabbedsection .MuiTabs-root {
  display: flex;
  overflow: hidden;
  min-height: 30px;
  -webkit-overflow-scrolling: touch;
}

.maintabbedsection .MuiTab-textColorPrimary.Mui-selected {
  color: #fff !important;
  background: #01b4f0 !important;
}

.maintabbedsection .MuiDropzoneArea-textContainer p {
  color: #d2d2d2;
  font-size: 16px;
}

.maintabbedsection .MuiDropzoneArea-textContainer .MuiDropzoneArea-icon {
  color: #267abd;
}

#investor_relations_tabs.maintabbedsection .MuiTab-root {
  border-radius: 4px !important;
  padding: 8px 12px !important;
}

#investor_relations_tabs_mob {
  display: none;
}

#investor_relations_tabs.stiky,
#investor_relations_tabs.stiky4 {
  display: block;
}

#investor_relations_tabs_mob .alltxfieldlg .MuiInputBase-root {
  font-size: 13px !important;
  background-color: #ffff !important;
  padding: 6px;
  border-radius: 4px !important;
  border: none !important;
  outline: none !important;
}

#investor_relations_tabs_mob .MuiListItemButton-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 30px !important;
}

@media (max-width: 889px) {

  #investor_relations_tabs_mob {
    display: block;
  }

  #esg.stiky3 #investor_relations_tabs {
   top: 135px !important;
  }

  #investor_relations_tabs.stiky,
  #investor_relations_tabs.stiky4 {
    display: none !important;
  }

  #investor_relations_tabs_mob.esg_tabs{
    top: 135px !important;
  }

}

@media (max-width: 600px) {

  #investor_relations_tabs_mob.esg_tabs{
    top: 115px !important;
  }

}