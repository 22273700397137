/* EmployeeProfile */
#EmployeeProfile {
    background: aliceblue;
    padding: 4px 12px;
    border-left: 6px solid #00b0f0 !important;
    border-radius: 8px !important;
}

#EmployeeProfile .teama {
    filter: grayscale(100);
    width: 130px;
    height: 130px;
}

#EmployeeProfile.dpa_profile .teama {
    filter: none !important;
    width: 130px;
    height: 130px;
}

#EmployeeProfile .line {
    width: 80px;
    height: 3px;
    background-color: #1930ab !important;
    margin-top: 2px !important;
}

#EmployeeProfile .MuiListItemAvatar-root {
    margin-right: 15px !important;
}

#EmployeeProfile .MuiListItem-root {
    padding: 0px !important;
}

#EmployeeProfile .linkedInicon {
    background-color: #1930ab1c !important;
    padding: 8px !important;
}

@media (max-width:767px) {
    #EmployeeProfile .teama {
        width: 100px;
        height: 100px;
        margin: 0px !important;
    }

    #EmployeeProfile.dpa_profile .teama {
        width: 100px;
        height: 100px;
    }
}


@media (max-width:599px) {
    #EmployeeProfile .MuiListItem-root {
        display: block;
    }
}