
.landingbannerbg1 {
    height: 80vh !important; 
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    display: grid !important;
    place-items: center !important;
    text-align: center !important; 
}

.landing-underline {
    height: 2px !important;
    width: 70px !important;
    background-color: var(--col2) !important;
}

.empowering-heading {
    font-size: 40px !important;
    line-height: 50px !important;
    font-weight: 300 !important;
}

.mil-relative {
    position: relative;
}

section {
    position: relative;
    overflow: hidden;
}

.mil-banner {
    height: 80vh; 
    z-index: -1200 !important;
}

.mil-animation-frame {
    width: 100%;
    height: 80vh;
    top: 0;
    animation: jump 10s linear infinite;
}

.mil-animation,
.mil-animation-frame {
    position: absolute;
    pointer-events: none;
}

.mil-animation {
    display: inline-block;
    width: 300px;
    height: 300px;
    opacity: 0.2;
}

.mil-position-1 {
    top: 100px;
    right: 100px;
}

.mil-position-1 .mil-pentagon div {
    border-top: 0.1px solid #1930ab;
}

.mil-position-2 {
    top: 100px;
    left: 5%;
}

.mil-position-2 .mil-pentagon div {
    border-top: 1.9px solid #1930ab;
}

.mil-position-2 .mil-dodecahedron {
    animation-delay: 0.5s;
}

.mil-position-3 {
    bottom: 20px;
    right: 45%;
}

.mil-position-3 .mil-pentagon div {
    border-top: 2px solid #1930ab;
}

.mil-position-3 .mil-dodecahedron {
    animation-delay: 1s;
}

.mil-position-4 {
    top: -60px;
    right: 20%;
}

.mil-position-4 .mil-pentagon div {
    border-top: 0.1px solid #1930ab;
}

.mil-position-4 .mil-dodecahedron {
    animation-delay: 0.5s;
}

.mil-position-4.mil-dark .mil-pentagon div {
    border-top: 0.1px solid #1930ab;
}

.mil-dodecahedron {
    position: relative;
    left: 100px;
    top: 40px;
    width: 100px;
    height: 223px;
    transform-style: preserve-3d;
    animation: rotate 100s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotateX(0deg) rotateY(1turn) rotate(1turn);
    }

    to {
        transform: rotateX(1turn) rotateY(0deg) rotate(-1turn);
    }
}

.mil-pentagon {
    position: absolute;
    width: 100px;
}

.mil-pentagon:first-child {
    transform: rotateY(72deg) translateZ(69px) rotateX(26.5deg);
}

.mil-pentagon:nth-child(6) {
    bottom: 0;
    transform: rotateY(72deg) translateZ(-69px) rotateX(206.5deg);
}

.mil-pentagon:nth-child(2) {
    transform: rotateY(0.4turn) translateZ(69px) rotateX(26.5deg);
}

.mil-pentagon:nth-child(7) {
    bottom: 0;
    transform: rotateY(0.4turn) translateZ(-69px) rotateX(206.5deg);
}

.mil-pentagon:nth-child(3) {
    transform: rotateY(0.6turn) translateZ(69px) rotateX(26.5deg);
}

.mil-pentagon:nth-child(8) {
    bottom: 0;
    transform: rotateY(0.6turn) translateZ(-69px) rotateX(206.5deg);
}

.mil-pentagon:nth-child(4) {
    transform: rotateY(0.8turn) translateZ(69px) rotateX(26.5deg);
}

.mil-pentagon:nth-child(9) {
    bottom: 0;
    transform: rotateY(0.8turn) translateZ(-69px) rotateX(206.5deg);
}

.mil-pentagon:nth-child(5) {
    transform: rotateY(1turn) translateZ(69px) rotateX(26.5deg);
}

.mil-pentagon:nth-child(10) {
    bottom: 0;
    transform: rotateY(1turn) translateZ(-69px) rotateX(206.5deg);
}

.mil-pentagon:nth-child(11) {
    transform: translateZ(69px) rotateX(-90deg);
}

.mil-pentagon:nth-child(12) {
    bottom: 0;
    transform: translateZ(-69px) rotateX(90deg);
}

.mil-pentagon div {
    position: absolute;
    width: 100px;
    height: 69px;
    transform-origin: bottom;
}

.mil-pentagon div:first-child {
    transform: rotate(72deg);
}

.mil-pentagon div:nth-child(2) {
    transform: rotate(0.4turn);
}

.mil-pentagon div:nth-child(3) {
    transform: rotate(0.6turn);
}

.mil-pentagon div:nth-child(4) {
    transform: rotate(0.8turn);
}

.mil-pentagon div:nth-child(5) {
    transform: rotate(1turn);
}

.mil-dark-bg .mi-invert-fix {
    height: 100%;
    position: relative;
    pointer-events: none;
    z-index: 3;
}

.mil-dark-bg {
    position: relative;
}

.mil-dark-bg:before {
    content: "";
    background-color: #f8f8f8 !important;
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    z-index: 2;
    -webkit-backdrop-filter: invert(100%);
    backdrop-filter: invert(100%);
}